import { memo } from "react"
import { Button, Stack } from "@chakra-ui/react"
import { useCustomerAccount } from "@app/hooks/useCustomer"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { useFormBuilder, FIELD_TYPES, ControlledInput, FunctionErrors } from "@app/hooks/useFormBuilder"

const AccountActivateForm: React.FC = () => {
  const { data, handleChange, handleActivateSubmit, loading, errors: functionErrors } = useCustomerAccount()
  const locales = useLocale(LOCALE_KEYS.ACCOUNT)

  const { fieldErrors, register, handleSubmit } = useFormBuilder({
    onSubmit: (data: any, event: any) => handleActivateSubmit(event),
  })

  return (
    <Stack as="form" onSubmit={handleSubmit}>
      <ControlledInput
        type={FIELD_TYPES.PASSWORD}
        register={register}
        onChange={handleChange}
        fieldErrors={fieldErrors}
        data={data}
        bg="brand.inputBg"
      />

      {locales?.accountActivateSubmitBtn && (
        <Button type="submit" isDisabled={loading} isLoading={loading}>
          {locales?.accountActivateSubmitBtn}
        </Button>
      )}

      <FunctionErrors errors={functionErrors} />
    </Stack>
  )
}

const MemoAccountActivateForm = memo(AccountActivateForm)
export { MemoAccountActivateForm as AccountActivateForm }
