import { Container, Box } from "@chakra-ui/react"
import { AccountActivateForm } from "./AccountActivateForm"
import { useContent } from "@app/hooks/useContent"
import { AccountBox } from "@components/Account/AccountBox"
import { memo } from "react"

import type { Props } from "@app/pages/account/activate"
import type { PageProps } from "@root/types/global"

const AccountActivate: React.FC<PageProps<Props>> = ({ page }) => {
  const { getContent } = useContent()
  const content = getContent({ content: page?.content })

  return (
    <Box as="section" pt={[12, 12, 15]} pb={[12, 12, 36]}>
      <Container maxW="md">
        <AccountBox title={page?.title} content={content}>
          <AccountActivateForm />
        </AccountBox>
      </Container>
    </Box>
  )
}

export default memo(AccountActivate)
